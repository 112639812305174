import { useLazyQuery } from "@apollo/client";

import { GET_ALL_USER_DATA, GET_USER } from "../graphql/queries/queriesUser";
import { useAuth } from "../utils/auth";

import { discoverData, familyData, rulesData, userData, promocodeData } from "../graphql/reactivities";
import { useEffect } from "react";
import { GET_CLUB_HEADER_DATA } from "../graphql/queries/queriesClub";

const useUser = ({ skip = false }) => {
  const { accessToken, isAuthorized, isReady } = useAuth();

  // const { data: { club: { discoverClubRequired = false } = {} } = {} } =
  //   useQuery(GET_CLUB_HEADER_DATA, {
  //     skip: skip,
  //     variables: { showOffer: false },
  //   });

  const [getClubDiscover, { data: { club: { discoverClubRequired = false } = {} } = {} }] = useLazyQuery(GET_CLUB_HEADER_DATA, {
    variables: { showOffer: false },
    // fetchPolicy: "network-only",
  });

  // const discoverClubRequired = false;

  const [getUser] = useLazyQuery(GET_USER, {
    // context: {
    //   headers: {
    //     authorization: `Token ${"rrrrr"}${"test" ? ` ${"test"}` : ""}`,
    //   },
    // },
    fetchPolicy: "network-only",
    // fetchPolicy: "cache-and-network",

    onCompleted: ({ user }) => {
      // console.log("user", user);

      userData(user);
      const { phone, firstName, email, phoneConfirmed, emailConfirmed, passwordConfirmed } = user;

      const userStorageData = {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      };

      localStorage.setItem("userData", JSON.stringify(userStorageData));
    },
  });

  const [getAllUserData, { error: rulesError }] = useLazyQuery(GET_ALL_USER_DATA, {
    fetchPolicy: "network-only",

    onCompleted: ({ profile, family = [] }) => {
      rulesData(profile?.rulesAccepted ?? false);
      discoverData(!!profile?.discoverClub);

      promocodeData(profile?.promocode);

      familyData(family);
    },

    onError: ({ graphQLErrors }) => {
      if (graphQLErrors?.[0]?.result === 7) {
        rulesData(false);
        discoverData(false);
        promocodeData("");
        familyData([]);
      }
    },
  }); //{ loading: rulesChecking, error: rulesError }

  useEffect(() => {
    //authorized on landing page
    if (skip && isAuthorized) {
      getUser();
      return;
    }

    //authorized on club page
    if (!skip && isAuthorized) {
      getUser();
      getAllUserData();
      getClubDiscover();
      // getClub();
      return;
    }

    //not authorized
    if (isReady && !isAuthorized) {
      const { phone, firstName, email, phoneConfirmed, emailConfirmed, passwordConfirmed } = userData();

      const user = {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      };

      userData(user);
      rulesData(false);
      discoverData(false);
      promocodeData("");
      familyData([]);

      localStorage.setItem("userData", JSON.stringify(user));
    }
  }, [isReady, isAuthorized, skip, accessToken]);

  return { isAuthorized, isReady, rulesError, discoverClubRequired }; // rulesChecking,
};

export default useUser;
