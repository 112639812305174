import React from "react";

import { TrainingPage } from "../../components/TrainingSection";

import { HocWithPreloader } from "../../../../../hocs/hocWithPreloader";
import useTrainingRequestData from "./useTrainingRequestData";

const TrainingWrapper = () => {
  const { data, isLoading, error, familyId, setFamilyId, promocode, setPromocode } = useTrainingRequestData();
  // console.log(data,'isLoading')
  return (
    <HocWithPreloader isLoading={isLoading} data={data} emptyText={error} withContainer>
      <div className="trainingPage">
        <TrainingPage data={data} familyProfileId={familyId} setFamilyId={setFamilyId} promocode={promocode} setPromocode={setPromocode} />
      </div>
    </HocWithPreloader>
  );
};

export default TrainingWrapper;
